export default {
  state: {
    email: /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,5}$/,
    lien: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
    pseudo: /[~`!@#$£¤*%§^&()={}[\]:;,<>+/?"'|\\ ]/,
    recherche: /[~`@#£¤*%§^()={}[\];<>/_"|\\]/,
    nombre: /([0-9]*[.,])?[0-9]+/
  },
  getters: {
    testEmail: (state) => (email) => {
      if (!state.email.test(email) || email.length === 0) {
        return false
      } else {
        return true
      }
    },
    testValeur: (state) => (valeur) => {
      if (valeur.length === 0) {
        return false
      } else {
        return true
      }
    },
    testCheck: (state) => (valeur) => {
      return valeur
    },
    testEgal: (state) => (valeur1, valeur2) => {
      return valeur1 === valeur2
    },
    testLength: (state) => (valeur, taille) => {
      return valeur.length > taille
    },
    testTexte: (state) => (type, texte) => {
      return state[type].test(texte)
    }
  },
  mutations: {},
  actions: {},
  modules: {}
}
