<template>
  <div class="columns is-centered">
    <div class="column has-text-centered">
      <language-switcher v-slot="{ links }">
        <router-link v-for="link in links" :key="link.langIndex" :to="link.url" :class="link.activeClass === '' ? 'p survole' : 'p active'">
          - {{ link.langName }} -
        </router-link>
      </language-switcher>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'ChangeLangue'
}
</script>

<style type="text/css" scoped>
@import '@/style/Footer.css'
</style>
