export default {
  state: {
    creation: {
      bdd: 0,
      stockage: 0,
      calendrier: 0,
      email: 0
    }
  },
  getters: {},
  mutations: {
    projetCreation: function (state, d) {
      state.creation[d] = 1
    },
    prejetCreationAnnuler: function (state) {
      state.creation = {
        bdd: 0,
        stockage: 0,
        calendrier: 0,
        email: 0
      }
    }
  },
  actions: {}
}
