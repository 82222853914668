<template>
  <BarreNavigation v-if="!['Dashboard', 'CGU', 'DataProtection', 'MentionLegale'].includes($route.name)"/>
  <router-view :key="$route.fullPath" />
  <PiedDePage v-if="!['Dashboard', 'CGU', 'DataProtection', 'MentionLegale'].includes($route.name)"/>
  <NotificationsSite />
</template>

<script type="text/javascript">
import BarreNavigation from '@/components/App/BarreNavigation.vue'
import PiedDePage from '@/components/App/PiedDePage.vue'
import NotificationsSite from '@/components/App/NotificationsSite.vue'
export default {
  name: 'App',
  components: {
    BarreNavigation,
    PiedDePage,
    NotificationsSite
  },
  beforeMount: function () {
    this.connexion()
  },
  methods: {
    connexion: function () {
      if (window.localStorage.getItem('tokenDW') !== null && window.localStorage.getItem('tokenDW') !== 'null') {
        this.$axios.get('membre/sconnexion?l=' + this.$i18n.locale + '&t=' + window.localStorage.getItem('tokenDW')).then((data) => {
          // OK
          this.$store.dispatch('connexion', data.data.information)
          window.localStorage.setItem('tokenDW', data.data.token)
          this.$store.state.connexionReussi = true
          if (window.sessionStorage.getItem('refresh') === null) {
            this.$store.commit('afficherNotification', { texte: this.$t('magiclink.reussi') + this.$store.state.prenom, type: 'ok' })
            window.sessionStorage.setItem('refresh', false)
            this.$router.push({ name: 'Dashboard' })
          }
          this.$router.push({ name: 'Dashboard' })
        }).catch((error) => {
          console.log(error)
          this.$store.commit('afficherNotification', { texte: error.response.data.message, type: 'erreur' })
          window.localStorage.removeItem('tokenDW')
        }).then(() => {
          this.$store.dispatch('wsStart')
        })
      } else {
        this.$store.dispatch('wsStart')
      }
      window.addEventListener("beforeunload", this.leave)
    },
    leave: function (e) {
      window.sessionStorage.setItem('refresh', true)
    }
  },
  beforeUnmount: function () {
    window.removeEventListener("beforeunload", this.leave)
  }
}
</script>