<template>
  <transition-group name="slide">
    <div v-for="(notif, i) in listeNotif" :key="notif" :class="notif.couleur" :id="'n' + i">
      <button class="delete croixNotif" @click="cacherNotification(notif.id)"/>
      <div class="columns is-vcentered is-mobile is-marginless">
        <div class="column is-1-tablet is-2-mobile">
          <span class="icon" v-html="notif.icon"/>
        </div>
        <div class="column is-10-tablet is-10-mobile" v-html="notif.texte"/>
      </div>
      <div class="loaderDisparition">
        <transition name="barre">
          <div v-if="notif.afficheBarre" :class="notif.fondProgress"/>
        </transition>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  name: 'NotificationsSite',
  data () {
    return {
      listeNotif: [],
      premierId: 0
    }
  },
  watch: {
    '$store.state.notifications.notif': {
      deep: true,
      handler (newVal, ancien) {
        if (newVal !== null && newVal !== ancien) {
          this.listeNotif.push(newVal)
          this.premierId = this.listeNotif[this.listeNotif.length - 1].id
          this.listeNotif[this.listeNotif.length - 1].timer = setTimeout(this.cacherNotification, 7000, this.listeNotif[this.listeNotif.length - 1].id)
          setTimeout(() => {
            this.listeNotif[this.listeNotif.length - 1].afficheBarre = true
          }, 100)
        }
      }
    }
  },
  methods: {
    cacherNotification: function (id) {
      for (var i = 0; i < this.listeNotif.length; i++) {
        if (this.listeNotif[i].id === id) {
          this.listeNotif[i].timer = null
          this.listeNotif.splice(i, 1)
        }
      }
      this.$store.commit('cacherNotification', id)
    }
  }
}
</script>

<style type="text/css" media="screen" scoped>
  @import '@/style/Notifications.css';
</style>