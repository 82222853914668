import {
  createApp
} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import {
  i18n
} from 'vue-lang-router'
import axios from 'axios'
import 'bulma/css/bulma.css'
import 'bulma-checkradio/dist/css/bulma-checkradio.min.css'
import './style/Texte.css'
import './style/Fond.css'
import './style/Cadre.css'
import './style/App.css'
import './style/Bouton.css'
import './style/Icone.css'
import './style/Input.css'
import './style/Checkbox.css'
import './style/Modal.css'
import './style/Scroll.css'
import './style/Tooltip.css'
import './style/Dashboard/Skeleton.css'
import './style/Message.css'

const axiosConfig = {
  baseURL: import.meta.env.DEV ? 'http://192.168.1.55:3000/' : 'https://' + window.location.host + '/api/',
  maxContentLength: 100000
}
const app = createApp(App).use(store).use(router).use(i18n)
app.config.globalProperties.$axios = axios.create(axiosConfig)
app.mount('#app')
