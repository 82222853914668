export default {
  state: {
    plan: [],
    paragraphes: []
  },
  getters: {},
  mutations: {
    rixbyPlanReset: function (state) {
      state.plan = []
      state.paragraphes = []
    },
    rixbyPlan: function (state, d) {
      state.plan = d.plan
      state.paragraphes = d.paragraphes
    },
    rixbyAjouterSousTitrePlan: function (state, {
      id,
      parent
    }) {
      const nouveau = {
        id: id,
        parent: parent,
        titre: null,
        sousTitre: "Sous titre",
        texteModif: null,
        paragraphes: []
      }
      let parentFound = false
      for (var i = 0; i < state.plan.length; i++) {
        if (parseInt(state.plan[i].id) === parseInt(parent)) {
          parentFound = true
        }
        if (parseInt(state.plan[i].id) !== parseInt(parent) && parseInt(state.plan[i].parent) !== parseInt(parent) && parentFound) {
          state.plan.splice(i, 0, nouveau)
          break;
        } else if (parentFound && i === state.plan.length - 1) {
          state.plan.push(nouveau)
          break;
        }
      }
    },
    rixbyAjouterTitrePlan: function (state, {
      titre,
      id
    }) {
      state.plan.push({
        id: id,
        parent: null,
        titre: titre,
        sousTitre: null,
        texteModif: null,
        paragraphes: []
      })
    },
    rixbyModifierTitrePlan: function (state, plan) {
      for (var i = 0; i < state.plan.length; i++) {
        if (state.plan[i].id === plan.id) {
          state.plan[i].titre = plan.titre
          state.plan[i].texteModif = null
        }
      }
    },
    rixbyDeplacementManuelParagraphe: function (state, {
      zone,
      zoneId,
      elementId,
      index,
      oldIndex,
      oldZone,
      oldZoneId,
      ws
    }) {
      if (ws) {
        let element = null
        // Faire le déplacement manuellement
        if (zone === 'plan' && oldZone === 'paragraphes') {
          // Supprimer dans paragraphes
          for (var i = 0; i < state.paragraphes.length; i++) {
            if (parseInt(state.paragraphes[i].id) === parseInt(oldZoneId)) {
              element = state.paragraphes[i].paragraphes[oldIndex]
              state.paragraphes[i].paragraphes.splice(oldIndex, 1)
              break;
            }
          }
          // Ajouter dans plan
          for (var i = 0; i < state.plan.length; i++) {
            if (parseInt(state.plan[i].id) === parseInt(zoneId)) {
              if (state.plan[i].paragraphes.length === 0) {
                state.plan[i].paragraphes.push(element)
              } else {
                state.plan[i].paragraphes.splice(index, 0, element)
              }
            }
          }
        } else if (zone === 'paragraphes' && oldZone === 'plan') {
          // Supprimer dans plan
          for (var i = 0; i < state.plan.length; i++) {
            if (parseInt(state.plan[i].id) === parseInt(oldZoneId)) {
              element = state.plan[i].paragraphes[oldIndex]
              state.plan[i].paragraphes.splice(oldIndex, 1)
              break;
            }
          }
          // Ajouter dans paragraphes
          for (var i = 0; i < state.paragraphes.length; i++) {
            if (parseInt(state.paragraphes[i].id) === parseInt(zoneId)) {
              if (state.paragraphes[i].paragraphes.length === 0) {
                state.paragraphes[i].paragraphes.push(element)
              } else {
                state.paragraphes[i].paragraphes.splice(element.pos, 0, element)
              }
            }
          }
        } else if (zone === oldZone && zone === 'plan') {
          if (zoneId === oldZoneId) {
            // Déplacement dans le même plan --> changement d'index
            for (var i = 0; i < state.plan.length; i++) {
              if (state.plan[i].id === zoneId) {
                element = state.plan[i].paragraphes[oldIndex]
                state.plan[i].paragraphes.splice(oldIndex, 1)
                state.plan[i].paragraphes.splice(index, 0, element)
                break;
              }
            }
          } else {
            // Déplacement dans un ordre plan
            // Suppression dans le premier plan
            for (var i = 0; i < state.plan.length; i++) {
              if (state.plan[i].id === oldZoneId) {
                element = state.plan[i].paragraphes[oldIndex]
                state.plan[i].paragraphes.splice(oldIndex, 1)
                break;
              }
            }
            // Ajout dans l'autre plan
            for (var i = 0; i < state.plan.length; i++) {
              if (state.plan[i].id === zoneId) {
                state.plan[i].paragraphes.splice(index, 0, element)
                break;
              }
            }
          }
        }
      }
      if (zone === 'paragraphes') {
        for (var i = 0; i < state.paragraphes.length; i++) {
          if (state.paragraphes[i].id = zoneId) {
            state.paragraphes[i].paragraphes = state.paragraphes[i].paragraphes.sort((a, b) => a.pos - b.pos)
          }
        }
      }
    }
  },
  actions: {
    rixbySupprimerSousTitrePlan: function ({
        state,
        dispatch
      },
      id) {
      let index = []
      let paragraphes = []
      for (var i = 0; i < state.plan.length; i++) {
        if (state.plan[i].id === id) {
          index.push(i)
          state.plan[i].paragraphes.forEach((p) => {
            paragraphes.push(p)
          })
        }
      }
      dispatch('rixbyDeplacerParagrapheOrigine', paragraphes).then(() => {
        for (var i = 0; i < state.plan.length; i++) {
          if (state.plan[i].id === id) {
            state.plan.splice(i, 1)
          }
        }
      })
    },
    rixbySupprimerTitrePlan: function ({
      state,
      dispatch
    }, id) {
      // Remettre automatiquement les paragraphes dans la liste
      let index = []
      let paragraphes = []
      for (var i = 0; i < state.plan.length; i++) {
        if (state.plan[i].id === id || state.plan[i].parent === id) {
          index.push(i)
          state.plan[i].paragraphes.forEach((p) => {
            paragraphes.push(p)
          })
        }
      }
      dispatch('rixbyDeplacerParagrapheOrigine', paragraphes).then(() => {
        for (var i = index.length - 1; i >= 0; i--) {
          state.plan.splice(index[i], 1)
        }
      })
    },
    rixbyDeplacerParagrapheOrigine: function ({
      state
    }, paragraphes) {
      let liste = {}
      for (var i = 0; i < paragraphes.length; i++) {
        if (paragraphes[i].source in liste) {
          liste[paragraphes[i].source].push(paragraphes[i])
        } else {
          liste[paragraphes[i].source] = []
          liste[paragraphes[i].source].push(paragraphes[i])
        }
      }
      for (var i = 0; i < state.paragraphes.length; i++) {
        if (state.paragraphes[i].id in liste) {
          liste[state.paragraphes[i].id].forEach((p) => {
            state.paragraphes[i].paragraphes.push(p)
          })
        }
      }
    }
  }
}
