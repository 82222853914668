import {
  createStore
} from 'vuex'
import notifications from './Notifications'
import regex from './Regex'
import securite from './Securite'
import socket from './Socket'
import device from 'current-device'
import loader from './Loader.js'
import rixby from './Rixby/index.js'
import axios from 'axios'
axios.defaults.baseURL =
import.meta.env.DEV ? 'http://192.168.1.55:3000/' : 'https://' + window.location.host + '/api/'

export default createStore({
  state: {
    numMembre: 0,
    nom: '',
    prenom: '',
    email: '',
    coins: 20,
    device: {
      mobile: device.mobile(),
      tablet: device.tablet(),
      desktop: device.desktop(),
    },
    clouds: {
      dropbox: 0,
      oneDrive: 0,
      googleDrive: 0
    },
    preferences: {
      notif: false
    },
    connexionReussi: false
  },
  getters: {
    isMobile: function () {
      return device.mobile()
    },
    isTablet: function () {
      return device.tablet()
    },
    isPc: function () {
      return device.desktop()
    }
  },
  mutations: {
    modification: function (state, d) {
      state.nom = d.nom
      state.prenom = d.prenom
      state.email = d.email
    },
    deconnexion: function (state) {
      state.numMembre = 0
      state.nom = ''
      state.prenom = ''
      state.email = ''
      state.coins = 20
      state.connexionReussi = false
      state.preferences.notif = false
    }
  },
  actions: {
    connexion: function ({
      state,
      commit,
      dispatch
    }, d) {
      state.numMembre = parseInt(d.numMembre)
      state.nom = d.nom
      state.prenom = d.prenom
      state.email = d.email
      state.coins = d.coins === 'Infinity' ? Infinity : d.coins
      state.clouds.dropbox = d.stockage.dropbox
      state.clouds.googleDrive = d.stockage.googleDrive
      state.clouds.oneDrive = d.stockage.oneDrive
      state.preferences.notif = d.preferences.notif
    },
    transaction: async function ({
      state,
      commit
    }, {
      quoi,
      prix,
      type,
      projetId = null
    }) {
      // Faire la transaction au back end
      if (quoi === "plus") {
        state.coins += prix
        return true
      } else {
        return await axios.post('transaction/depense?l=' + window.localStorage.getItem('VueAppLanguage') + '&m=' + state.numMembre, {
          prix: prix,
          action: type,
          projetId: projetId
        }).then(({
          data
        }) => {
          state.coins -= prix
          return {
            s: true,
            idTransaction: data.idT
          }
        }).catch((error) => {
          commit('afficherNotification', {
            texte: error.response.data.message.join('<br>'),
            type: 'erreur'
          }, {
            root: true
          })
          return {
            s: false
          }
        })
      }
    }
  },
  modules: {
    notifications,
    regex,
    securite,
    socket,
    loader,
    rixby
  }
})
