<template>
  <div class="top run-animation">
    <div class="columns is-centered is-vcentered forLargeScreen">
      <div class="column is-5-tablet is-10-mobile is-offset-1-mobile scrollReveal gauche">
        <p class="has-text-white grandTitre"><b>{{ $t('acceuil.t1') }}</b></p>
        <p class="has-text-white grandTitre">{{ $t('acceuil.t2') }}</p>
      </div>
      <div class="column has-text-right-touch is-offset-1-tablet is-3-tablet scrollReveal droite is-10-mobile is-offset-1-mobile">
        <picture>
          <source srcset="@/assets/Smartphone_dark.svg" media="(prefers-color-scheme: dark)">
          <img src="@/assets/Smartphone.svg" class="smartphone">
        </picture>
      </div>
    </div>
  </div>
  <!-- Droite -->
  <div class="columns hautdepage is-centered is-vcentered middle forLargeScreen scrollReveal millieu">
    <div class="column has-text-centered is-9-tablet is-10-mobile is-offset-1-mobile">
      <h3 class="titre content"><b>{{ $t('acceuil.idee.t') }}</b></h3>
      <div class="columns is-centered is-multiline is-mobile">
        <div class="column has-text-centered p idees fond1 cadre is-5-mobile">
          <span class="icon is-large">
            <i class="fa-duotone fa-3x fa-presentation-screen"></i>
          </span>
          <b>{{ $t('acceuil.idee.a') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-5-mobile is-offset-1-mobile">
          <span class="icon is-large">
            <i class="fa-duotone fa-3x fa-file-lines"></i>
          </span>
          <b>{{ $t('acceuil.idee.b') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-5-mobile">
          <span class="icon is-large">
            <i class="fa-duotone fa-3x fa-square-kanban"></i>
          </span>
          <b>{{ $t('acceuil.idee.c') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-5-mobile is-offset-1-mobile">
          <span class="icon is-large">
            <i class="fa-duotone fa-3x fa-newspaper"></i>
          </span>
          <b>{{ $t('acceuil.idee.d') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-5-mobile">
          <span class="icon is-large">
            <i class="fa-duotone fa-3x fa-hashtag"></i>
          </span>
          <b>{{ $t('acceuil.idee.e') }}</b>
        </div>
      </div>
    </div>
  </div>
  <div class="columns is-centered is-vcentered middle forLargeScreen">
    <div class="column is-4-tablet is-10-mobile is-offset-1-mobile scrollReveal gauche">
      <h1 class="titre content">{{ $t('acceuil.st1') }}</h1>
      <p class="p content">{{ $t('acceuil.p1') }}</p>
    </div>
    <div class="column is-4-tablet is-offset-1-tablet scrollReveal droite is-10-mobile is-offset-1-mobile lottie">
      <lottie-player src="/Images/Acceuil/DW_darkmode_4.json" background="transparent" speed="1" loop autoplay v-if="colorScheme === 'darkmode'"/>
      <lottie-player src="/Images/Acceuil/DW_lightmode_4.json" background="transparent" speed="1" loop autoplay v-else/>
    </div>
  </div>
  <!-- Gauche -->
  <div class="columns is-centered is-vcentered middle forLargeScreen">
    <div class="column is-4-tablet scrollReveal gauche is-10-mobile is-offset-1-mobile lottie is-hidden-mobile">
      <lottie-player src="/Images/Acceuil/DW_darkmode_2.json" background="transparent" speed="1" loop autoplay v-if="colorScheme === 'darkmode'"/>
      <lottie-player src="/Images/Acceuil/DW_lightmode_2.json" background="transparent" speed="1" loop autoplay v-else/>
    </div>
    <div class="column is-4-tablet is-offset-1-tablet scrollReveal droite is-10-mobile is-offset-1-mobile">
      <h1 class="titre content">{{ $t('acceuil.st2') }}</h1>
      <p class="p content">{{ $t('acceuil.p2') }}</p>
    </div>
    <div class="column is-4-tablet scrollReveal gauche is-10-mobile is-offset-1-mobile lottie is-hidden-tablet">
      <lottie-player src="/Images/Acceuil/DW_darkmode_2.json" background="transparent" speed="1" loop autoplay v-if="colorScheme === 'darkmode'"/>
      <lottie-player src="/Images/Acceuil/DW_lightmode_2.json" background="transparent" speed="1" loop autoplay v-else/>
    </div>
  </div>
  <!-- Droite -->
  <div class="columns is-centered is-vcentered middle forLargeScreen">
    <div class="column is-4-tablet scrollReveal gauche is-10-mobile is-offset-1-mobile">
      <h1 class="titre content">{{ $t('acceuil.st3') }}</h1>
      <p class="p content">{{ $t('acceuil.p3') }}</p>
    </div>
    <div class="column is-4-tablet is-offset-1-tablet scrollReveal droite is-10-mobile is-offset-1-mobile lottie">
      <lottie-player src="/Images/Acceuil/DW_darkmode.json" background="transparent" speed="1" loop autoplay v-if="colorScheme === 'darkmode'"/>
      <lottie-player src="/Images/Acceuil/DW_lightmode.json" background="transparent" speed="1" loop autoplay v-else/>
    </div>
  </div>
  <!-- Gauche -->
  <div class="columns is-centered is-vcentered middle forLargeScreen">
    <div class="column is-4-tablet scrollReveal gauche is-10-mobile is-offset-1-mobile lottie is-hidden-mobile">
      <lottie-player src="/Images/Acceuil/DW_darkmode_3.json" background="transparent" speed="1" loop autoplay v-if="colorScheme === 'darkmode'"/>
      <lottie-player src="/Images/Acceuil/DW_lightmode_3.json" background="transparent" speed="1" loop autoplay v-else/>
    </div>
    <div class="column is-4-tablet is-offset-1-tablet scrollReveal droite is-10-mobile is-offset-1-mobile">
      <h1 class="titre content">{{ $t('acceuil.st4') }}</h1>
      <p class="p content">{{ $t('acceuil.p4') }}</p>
    </div>
    <div class="column is-4-tablet scrollReveal gauche is-10-mobile is-offset-1-mobile lottie is-hidden-tablet">
      <lottie-player src="/Images/Acceuil/DW_darkmode_3.json" background="transparent" speed="1" loop autoplay v-if="colorScheme === 'darkmode'"/>
      <lottie-player src="/Images/Acceuil/DW_lightmode_3.json" background="transparent" speed="1" loop autoplay v-else/>
    </div>
  </div>
  <div class="columns basdepage scrollReveal millieu">
    <div class="column has-text-centered is-10-mobile is-offset-1-mobile">
      <h3 class="soustitre content has-text-white"><b>{{ $t('acceuil.t3') }}</b></h3>
    </div>
  </div>
  <div class="columns basdepage2 is-centered is-vcentered middle forLargeScreen scrollReveal millieu">
    <div class="column has-text-centered is-9-tablet is-10-mobile is-offset-1-mobile">
      <h3 class="p content"><b>{{ $t('acceuil.dev.t') }}</b></h3>
      <h3 class="soustitre content"><b>{{ $t('acceuil.dev.gp') }}</b></h3><br>
      <div class="columns is-centered is-multiline">
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.a') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.b') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.c') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.d') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.e') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.f') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.g') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.h') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.i') }}</b>
        </div>
        <div class="column has-text-centered p idees fond1 cadre is-narrow-tablet">
          <b>{{ $t('acceuil.dev.j') }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'PageAcceuil',
  data () {
    return {
      rtime: 0,
      timeout: false,
      delta: 50,
      colorScheme: 'lightmode'
    }
  },
  mounted: function () {
    // Resize pour ne casser l'animation CSS
    document.addEventListener('resize', () => {
      this.rtime = new Date()
      if (!this.timeout) {
        this.timeout = true
        setTimeout(this.resizeend, this.delta)
        document.querySelector('.top').classList.remove('run-animation')
      }
    })
    // Affichage au fur et à mesure
    document.querySelectorAll('.scrollReveal').forEach((i) => {
      if (i) {
        const observer = new IntersectionObserver((entries) => {
          observerCallback(entries, observer, i)
        }, {
          threshold: 0.2
        })
        observer.observe(i)
      }
    })
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
        }
      })
    }
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.colorScheme = 'darkmode'
    }
    this.compteSupprimerAnnuler()
  },
  methods: {
    resizeend: function () {
      const time = new Date() - this.rtime
      if (time < this.delta) {
        setTimeout(this.resizeend, this.delta)
      } else {
        this.timeout = false
        console.log('Done')
        document.querySelector('.top').classList.add('run-animation')
      }
    },
    compteSupprimerAnnuler: function () {
      if (Object.keys(this.$route.query).length > 0 && Object.keys(this.$route.query).indexOf('cdv') !== -1 && !window.sessionStorage.getItem('acs')) {
        const codeA = this.$route.query.cdv.replaceAll(' ', '+')
        this.$axios.delete('membre/asuppression?l=' + this.$i18n.locale + '&c=' + codeA).then((data) => {
          this.$store.commit('afficherNotification', { texte: this.$t('acceuil.asuppression'), type: 'ok' })
          window.sessionStorage.setItem('acs', true)
        }).catch((error) => {
          console.log(error)
          this.$store.commit('afficherNotification', { texte: error.response.data.message.join('<br>'), type: 'erreur' })
        })
      }
    }
  }
}
</script>

<style type="text/css" scoped>
  @import '@/style/Accueil.css';
</style>