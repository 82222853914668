export default {
  state: {
    active: 0
  },
  getters: {
    rixbyRechercheActive: function (state) {
      return state.active
    }
  },
  mutations: {},
  actions: {}
}
