export default {
  state: {
    deadline: null,
    vitesse: null,
    jours: 0,
    restant: 0,
    debut: null,
    etapes: {
      recherche: {
        i: 0,
        temps: 0,
        valider: false
      },
      plan: {
        i: 0,
        temps: 0,
        valider: false
      },
      discours: {
        i: 0,
        temps: 0,
        valider: false
      },
      diapo: {
        i: 0,
        temps: 0,
        valider: false
      }
    },
    vListe: {}
  },
  getters: {
    rixbyNbrValider: function (state) {
      return Object.keys(state.vListe).length
    }
  },
  mutations: {
    rixbyCalendrierReset: function (state) {
      state.deadline = null
      state.vitesse = null
      state.jours = 0
      state.restant = 0
      state.debut = null
      state.etapes.recherche = {
        i: 0,
        temps: 0,
        valider: false
      }
      state.etapes.plan = {
        i: 0,
        temps: 0,
        valider: false
      }
      state.etapes.discours = {
        i: 0,
        temps: 0,
        valider: false
      }
      state.etapes.diapo = {
        i: 0,
        temps: 0,
        valider: false
      }
      state.vListe = {}
    },
    rixbyCalendrier: function (state, d) {
      state.deadline = d.deadline
      state.vitesse = d.vitesse
      state.jours = d.jours
      state.restant = d.restant
      state.etapes.recherche = d.recherche
      state.etapes.plan = d.plan
      state.etapes.discours = d.discours
      state.etapes.diapo = d.diapo
      state.debut = d.debut
      state.vListe = d.vListe
    },
    rixbyValiderEtape: function (state, action) {
      if (action.quoi === 'ajouter') {
        state.vListe[action.qui] = true
      } else {
        delete state.vListe[action.qui]
      }
    }
  },
  actions: {}
}
