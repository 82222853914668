export default {
  state: {
    ws: null,
    wsId: '',
    lien: import.meta.env.DEV ? 'ws://192.168.1.55:3000/index.js' : 'wss://' + window.location.hostname + '/api/',
    actions: {
      reloadDashboard: false
    },
    collab: {},
    quillOps: null
  },
  getters: {
    wsId: function (state) {
      return state.wsId
    }
  },
  mutations: {
    wsIdPrefetch: function (state) {
      if (window.sessionStorage.getItem('wsId') !== null) {
        state.wsId = window.sessionStorage.getItem('wsId')
      }
    }
  },
  actions: {
    wsStart: async function ({
      state,
      rootState,
      commit,
      dispatch
    }) {
      commit('wsIdPrefetch')
      state.ws = new WebSocket(state.lien + '?wsId=' + state.wsId)
      state.ws.onopen = () => {
        console.log('WS open')
        state.ws.send(JSON.stringify({
          message: 'Hello'
        }))
        console.log(state.ws)
      }
      state.ws.onmessage = (msg) => {
        console.log(msg)

        const data = JSON.parse(msg.data)
        switch (data.type) {
        case 'con':
          state.wsId = data.data.id
          window.sessionStorage.setItem('wsId', data.data.id)
          if (rootState.numMembre !== 0) {
            dispatch('wsSend', {
              type: 'con',
              data: {
                membre: rootState.numMembre,
                projet: rootState.rixby.configuration.id ? rootState.rixby.configuration.id : 0
              }
            })
          }
          break;
        case 'magiclink':
          if (data.ok) {
            rootState.connexionReussi = true
          }
          break;
        case 'projetCreation':
          commit('projetCreation', data.etape)
          break;
        case 'ping':
          state.ws.send(JSON.stringify({
            type: 'pong',
            data: null
          }))
          break;
        case 'reloadDashboard':
          state.actions.reloadDashboard = true
          break;
        case 'rixby':
          console.log(data)
          if (data.projetType === 'rixby') {
            dispatch('wsRixby', {
              data: data.data,
              projetId: data.projetId
            })
          }
          break;
        case 'quill':
          if (data.data.quoi === 'state') {
            if (data.data.state) {
              dispatch('addCollab', [data.data.qui])
            } else {
              dispatch('deleteCollab', data.data.qui)
            }
          } else if (data.data.quoi === 'update') {
            state.quillOps = {
              ops: data.data.ops,
              qui: data.data.qui
            }
          }
          break;
        }
      }
      state.ws.onclose = () => {
        console.log('Fermeture de WS')
        dispatch('wsStart')
      }
    },
    wsSend: function ({
      state,
      dispatch
    }, {
      type,
      data
    }) {
      if (state.ws.readyState === WebSocket.OPEN) {
        state.ws.send(JSON.stringify({
          type: type,
          data: data
        }))
      } else if (state.ws.readyState === WebSocket.CONNECTING) {
        state.ws.addEventListener('open', () => {
          dispatch('wsSend', {
            type: type,
            data: data
          })
        })
      }
    },
    addCollab: function ({
      state,
      rootState,
      dispatch
    }, collab) {
      for (var i = 0; i < collab.length; i++) {
        for (let key in rootState.rixby.configuration.membres) {
          if (rootState.rixby.configuration.membres[key].membreId === collab[i]) {
            state.collab[key] = true
          }
        }
      }
    },
    deleteCollab: function ({
      state,
      rootState
    }, collab) {
      for (let key in rootState.rixby.configuration.membres) {
        if (rootState.rixby.configuration.membres[key].membreId === collab) {
          delete(state.collab[key])
        }
      }
    }
  },
  modules: {}
}
