import {
  createWebHistory
} from 'vue-router'
import translations from '../lang/translations'
import localizedURLs from '../lang/localized-urls'
import {
  createLangRouter
} from 'vue-lang-router'
import Acceuil from '@/views/Acceuil.vue'

const routes = [
  {
    path: '/',
    name: 'PageAcceuil',
    component: Acceuil
  },
  {
    path: '/connexion',
    name: 'Connexion',
    component: () =>
    import ('../views/Connexion.vue')
  },
  {
    path: '/inscription',
    name: 'Inscription',
    component: () =>
    import ('../views/Inscription.vue')
  },
  {
    path: '/magiclink',
    name: 'MagicLink',
    component: () =>
    import ('../views/MagicLink.vue')
  },
  {
    path: '/tableaudebord',
    name: 'Dashboard',
    component: () =>
    import ('../views/Dashboard.vue')
  },
  {
    path: '/tableaudebord/cgu',
    name: 'CGU',
    component: () =>
    import ('../views/Dashboard.vue')
  },
  {
    path: '/tableaudebord/dataprotection',
    name: 'DataProtection',
    component: () =>
    import ('../views/Dashboard.vue')
  },
  {
    path: '/tableaudebord/mentionlegale',
    name: 'MentionLegale',
    component: () =>
    import ('../views/Dashboard.vue')
  }
]

const langRouterOptions = {
  defaultLanguage: 'fr',
  translations,
  localizedURLs
}
const routerOptions = {
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
}
const router = createLangRouter(langRouterOptions, routerOptions)

export default router
