<template>
  <div class="column is-2 is-offset-1">
    <p class="soustitre"><b>{{ $t('nav.compte') }}</b></p>
    <br>
    <localized-link to="/connexion" v-if="$store.state.numMembre === 0 || !$store.state.connexionReussi">
      <p><span class="p survole">{{ $t('nav.connexion') }}</span></p>
    </localized-link>
    <localized-link to="/inscription" v-if="$store.state.numMembre === 0 || !$store.state.connexionReussi">
      <p><span class="p survole">{{ $t('nav.inscription') }}</span></p>
    </localized-link>
    <localized-link to="/tableaudebord" v-if="$store.state.numMembre !== 0 && $store.state.connexionReussi">
      <p><span class="p survole">{{ $t('nav.dashboard') }}</span></p>
    </localized-link>
    <a href="#" v-if="$store.state.numMembre !== 0 && $store.state.connexionReussi" @click="deconnexion()">
      <p><span class="p survole">{{ $t('nav.deconnect') }}</span></p>
    </a>
  </div>
  <div class="column is-4">
    <p class="soustitre"><b>{{ $t('nav.app') }}</b></p>
    <br>
    <div class="liens is-size-5-tablet is-size-6-mobile">
      <!-- <localized-link to="#">
        <p><span class="p survole">{{ $t('nav.contact') }}</span></p>
      </localized-link> -->
      <localized-link to="/tableaudebord/cgu">
        <p><span class="p survole">{{ $t('nav.cgu') }}</span></p>
      </localized-link>
      <localized-link to="/tableaudebord/dataprotection">
        <p><span class="p survole">{{ $t('nav.pdd') }}</span></p>
      </localized-link>
      <localized-link to="/tableaudebord/mentionlegale">
        <p><span class="p survole">{{ $t('nav.ml') }}</span></p>
      </localized-link>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'SiteMap',
  methods: {
    deconnexion: function () {
      this.$axios.delete('membre/deconnexion?l=' + this.$i18n.locale + '&t=' + window.localStorage.getItem('tokenDW')).then((data) => {
        this.$store.commit('deconnexion')
        this.$router.push({ name: 'PageAcceuil' })
        window.localStorage.removeItem('tokenDW')
        this.$store.commit('afficherNotification', { texte: this.$t('nav.deconnectOk'), type: 'ok' })
      }).catch((error) => {
        console.log(error)
        this.$store.commit('afficherNotification', { texte: error.response.data.message.join('<br>'), type: 'erreur' })
      }).then(() => {
        this.chargement = false
      })
    }
  }
}
</script>

<style type="text/css" scoped>
@import '@/style/Footer.css'
</style>
