export default {
  fr: {
    name: 'Français',
    load: () => { return import('./fr.json') }
  },
  en: {
    name: 'English',
    load: () => { return import('./en.json') }
  }
}
