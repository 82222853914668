export default {
  state: {
    listeTacheActive: 'recherche',
    listeTacheEncours: 'recherche',
    recherche: [],
    plan: [],
    discours: [],
    diapo: []
  },
  getters: {
    listeTacheActive: function (state) {
      return state[state.listeTacheActive]
    },
    listeTacheEncours: function (state) {
      return state[state.listeTacheEncours]
    },
    tache: (state) => (id) => {
      return state[state.listeTacheEncours].filter((tache) => {
        return tache.id === id
      })[0]
    }
  },
  mutations: {
    rixbyTachesReset: function (state) {
      state.recherche = []
      state.plan = []
      state.discours = []
      state.diapo = []
    },
    uptdateTaches: function (state, value) {
      for (var i = 0; i < value.length; i++) {
        value[i].pos = i
      }
      state[state.listeTacheActive] = value.sort((a, b) => a.valider - b.valider)
    },
    rixbyAjouterTache: function (state, nouveau) {
      nouveau.texteModif = null
      state[nouveau.liste].push(nouveau)
    },
    rixbySupprimerTache: function (state, tache) {
      for (var i = 0; i < state[tache.liste].length; i++) {
        if (state[tache.liste][i].id === tache.id) {
          state[tache.liste].splice(i, 1)
          break;
        }
      }
      for (var i = 0; i < state[tache.liste].length; i++) {
        if (state[tache.liste][i].pos > tache.pos) {
          state[tache.liste][i].pos--
        }
      }
      state[tache.liste] = state[tache.liste].sort((a, b) => a.valider - b.valider)
    },
    rixbyCheckTaches: function (state, tache) {
      for (var i = 0; i < state[tache.liste].length; i++) {
        if (state[tache.liste][i].pos > tache.pos && tache.valider) {
          state[tache.liste][i].pos--
        }
        if (state[tache.liste][i].pos >= tache.newPos && !tache.valider) {
          state[tache.liste][i].pos++
        }
        if (state[tache.liste][i].id === tache.id) {
          state[tache.liste][i].pos = tache.newPos
          state[tache.liste][i].valider = tache.valider
        }
      }
      state[tache.liste] = state[tache.liste].sort((a, b) => a.pos - b.pos)
    },
    rixbyDeplacerTache: function (state, tache) {
      for (var i = 0; i < state[tache.liste].length; i++) {
        if (tache.newPos > tache.oldPos && state[tache.liste][i].pos <= tache.newPos && state[tache.liste][i].id !== tache.id) {
          state[tache.liste][i].pos--
        } else if (tache.newPos < tache.oldPos && state[tache.liste][i].pos >= tache.newPos && state[tache.liste][i].id !== tache.id) {
          state[tache.liste][i].pos++
        }
        if (state[tache.liste][i].id === tache.id) {
          state[tache.liste][i].pos = tache.newPos
        }
      }
      state[tache.liste] = state[tache.liste].sort((a, b) => a.pos - b.pos)
    },
    rixbyAssignerTache: function (state, tache) {
      for (var i = 0; i < state[tache.liste].length; i++) {
        if (state[tache.liste][i].id === tache.id) {
          state[tache.liste][i].assign = tache.assign
        }
      }
    },
    rixbyTexteTache: function (state, tache) {
      for (var i = 0; i < state[tache.liste].length; i++) {
        if (state[tache.liste][i].id === tache.id) {
          state[tache.liste][i].texte = tache.texte
          state[tache.liste][i].texteModif = null
        }
      }
    }
  },
  actions: {
    rixbyInitTaches: function ({
      state,
      rootState
    }, taches) {
      for (var i = 0; i < taches.length; i++) {
        taches[i].texteModif = null
        if (taches[i].liste === 'discours' && taches[i].preview !== null) {
          taches[i].preview = JSON.parse(taches[i].preview)
        }
        state[taches[i].liste].push(taches[i])
      }
    },
    rixbyUpdateRecherchePreview: function ({
      state,
      getters
    }, {
      id,
      preview
    }) {
      getters.tache(id).preview = preview
    }
  }
}
