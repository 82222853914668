export default {
  state: {
    id: 0,
    nom: {
      valeur: null,
      modifier: 62
    },
    type: null,
    stockage: {},
    fermer: false,
    membres: {},
    membresAttente: {},
    createur: {},
    avancement: 0,
    moi: 0,
    vListe: {}
  },
  getters: {
    moiInProjet: (state) => (id) => {
      return state.membres[id].prenom + ' ' + state.membres[id].nom
    },
    rixbyNbrMembre: function (state) {
      return Object.keys(state.membres).length
    }
  },
  mutations: {
    rixbyConfigurationReset: function (state) {
      state.id = 0
      state.nom = {
        valeur: null,
        modifier: null
      }
      state.type = null
      state.stockage = {}
      state.fermer = false
      state.membres = {}
      state.membresAttente = {}
      state.createur = {}
      state.avancement = 0
      state.moi = 0
      state.vListe = {}
    },
    rixbyConfiguration: function (state, d) {
      state.id = d.id
      state.nom = {
        valeur: d.nom,
        modifier: null
      }
      state.type = d.type
      state.stockage = d.stockage
      state.fermer = d.fermer
      state.membres = d.membres
      state.membresAttente = d.membresAttente
      state.createur = d.createur
      state.avancement = d.avancement
      state.vListe = d.vListe
      console.log(d)
      for (let key in state.membres) {
        if (parseInt(state.membres[key].membreId) === parseInt(d.moi)) {
          state.moi = parseInt(key)
        }
      }
    },
    rixbyNom: function (state, nom) {
      state.nom.valeur = nom
      state.nom.modifier = null
    },
    rixbyMembreSupprimer: function (state, participant) {
      delete state.membres[parseInt(participant)]
    },
    rixbyNonMembreSupprimer: function (state, participant) {
      delete state.membresAttente[parseInt(participant)]
    },
    rixbyAjouterMembre: function (state, membre) {
      state.membres[membre.projetMembre] = membre
    },
    rixbyAjouterNonMembre: function (state, membre) {
      state.membresAttente[membre.projetMembre] = membre.email
    }
  },
  actions: {
    rixbyCloture: function ({
      state,
      dispatch,
      rootState
    }, action) {
      if (action === 'forcer') {
        rootState.rixby.projetCloture = state.id
        state.id = 0
        setTimeout(() => {
          dispatch('resetAll')
        }, 200)
      } else {
        if (action.quoi === 'ajouter') {
          state.vListe[action.qui] = true
        } else {
          delete state.vListe[action.qui]
        }
      }
    }
  }
}
