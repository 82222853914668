import configuration from './Configuration.js'
import calendrier from './Calendrier.js'
import taches from './Taches.js'
import recherche from './Recherche.js'
import plan from './Plan.js'
export default {
  state: {
    discoursActive: 0,
    monDiscoursId: 0,
    discoursCommun: 0,
    projetCloture: 0
  },
  getters: {},
  mutations: {
    rixby_modifInReceive: function (state, {
      qui,
      objet
    }) {
      objet = objet.split('_')
      switch (objet[0]) {
      case 'nom':
        state.configuration.nom.modifier = qui
        break;
      case 'tache':
        for (var i = 0; i < state.taches[objet[1]].length; i++) {
          if (parseInt(state.taches[objet[1]][i].id) === parseInt(objet[2])) {
            state.taches[objet[1]][i].texteModif = parseInt(qui)
            break;
          }
        }
        break;
      case 'titreplan':
        for (var i = 0; i < state.plan.plan.length; i++) {
          if (state.plan.plan[i].id === parseInt(objet[1])) {
            state.plan.plan[i].texteModif = parseInt(qui)
            break;
          }
        }
        break;
      case 'soustitreplan':
        for (var i = 0; i < state.plan.plan.length; i++) {
          if (state.plan.plan[i].id === parseInt(objet[1])) {
            state.plan.plan[i].texteModif = parseInt(qui)
            break;
          }
        }
        break;
      }
    },
    rixbyTerminerEtape: function (state, action) {
      // Avancement
      state.configuration.avancement += 25
      // Taches
      state.taches[state.taches.listeTacheEncours].forEach((tache) => {
        tache.terminer = true
      })
      // Calendrier
      state.calendrier.etapes[state.taches.listeTacheEncours].encours = false
      state.calendrier.etapes[state.taches.listeTacheEncours].valider = true
      // Changer de liste active
      const i = state.calendrier.etapes[state.taches.listeTacheEncours].i
      for (let key in state.calendrier.etapes) {
        if (state.calendrier.etapes[key].i === i + 1) {
          state.taches.listeTacheEncours = key
          state.taches.listeTacheActive = key
        }
      }
      state.taches.vListe = {}
      // Calendrier new
      state.calendrier.etapes[state.taches.listeTacheEncours].encours = true
      // Discours
      if ('nouvelleTache' in action) {
        action.nouvelleTache.forEach((tache) => {
          tache.texteModif = null
          state.taches[state.taches.listeTacheEncours].push(tache)
        })
      }
    },
    rixbyRetourEtape: function (state, action) {
      // Avancement
      state.configuration.avancement -= 25
      // Calendrier
      state.calendrier.etapes[state.taches.listeTacheEncours].encours = false
      state.calendrier.etapes[state.taches.listeTacheEncours].valider = false
      // Changer de liste active
      const i = state.calendrier.etapes[state.taches.listeTacheEncours].i
      for (let key in state.calendrier.etapes) {
        if (state.calendrier.etapes[key].i === i - 1) {
          state.taches.listeTacheEncours = key
          state.taches.listeTacheActive = key
        }
      }
      state.taches.vListe = {}
      // Calendrier new
      state.calendrier.etapes[state.taches.listeTacheEncours].encours = true
      state.calendrier.etapes[state.taches.listeTacheEncours].valider = false
    }
  },
  actions: {
    rixbyChargement: function ({
      commit,
      dispatch,
      rootState,
      getters,
      state
    }, {
      configuration,
      calendrier,
      taches
    }) {
      commit('rixbyCalendrierReset')
      commit('rixbyConfigurationReset')
      commit('rixbyTachesReset')
      commit('rixbyPlanReset')
      commit('rixbyConfiguration', configuration)
      commit('rixbyCalendrier', calendrier)
      dispatch('rixbyInitTaches', taches)
      for (let key in state.calendrier.etapes) {
        if (state.calendrier.etapes[key].encours) {
          state.taches.listeTacheActive = key
          state.taches.listeTacheEncours = key
        }
      }
    },
    resetAll: function ({
      commit
    }) {
      commit('rixbyCalendrierReset')
      commit('rixbyConfigurationReset')
      commit('rixbyTachesReset')
      commit('rixbyPlanReset')
    },
    wsRixby: function ({
      commit,
      state,
      dispatch
    }, {
      data,
      projetId
    }) {
      console.log(data)
      if (parseInt(state.configuration.id) === parseInt(projetId)) {
        if (data.quoi === 'modifIn') {
          commit('rixby_modifInReceive', {
            qui: data.qui,
            objet: data.objet
          })
        } else {
          const dispatchs = ['rixbySupprimerTitrePlan', 'rixbySupprimerSousTitrePlan', 'rixbyCloture']
          if (dispatchs.indexOf(data.quoi) !== -1) {
            dispatch(data.quoi, data.data)
          } else {
            commit(data.quoi, data.data)
          }
        }
      }
    },
    rixby_modifIn: function ({
      dispatch,
      state,
      rootState,
      getters
    }, objet) {
      dispatch('wsSend', {
        type: 'modifIn',
        data: {
          projetId: state.configuration.id,
          membreId: rootState.numMembre,
          membreProjet: state.configuration.moi,
          objet: objet
        }
      })
    }
  },
  modules: {
    configuration,
    calendrier,
    taches,
    recherche,
    plan
  }
}
