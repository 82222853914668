<template>
  <!-- Ecran -->
  <div class="nav fond1 cadre is-hidden-touch">
    <localized-link to="/">
      <div class="barre">
        <img class="logo" src="@/assets/logo.svg" alt="Logo">
        <p :class="'titre survole ' + ($route.name === 'Accueil' ? 'active' : '')">Dream Web</p>
      </div>
    </localized-link>
    <div class="barre">
      <localized-link v-if="$store.state.numMembre === 0 || !$store.state.connexionReussi" to="/inscription">
        <p :class="'titre survole ' + ($route.name === 'Inscription' ? 'active' : '')">{{ $t('nav.inscription') }}</p>
      </localized-link>
      <localized-link v-if="$store.state.numMembre === 0 || !$store.state.connexionReussi" to="/connexion">
        <p :class="'titre survole ' + ($route.name === 'Connexion' ? 'active' : '')">{{ $t('nav.connexion') }}</p>
      </localized-link>
      <localized-link v-if="$store.state.numMembre !== 0 && $store.state.connexionReussi" to="/tableaudebord">
        <p :class="'titre survole ' + ($route.name === '' ? 'active' : '')">{{ $t('nav.dashboard') }}</p>
      </localized-link>
      <a href="#" v-if="$store.state.numMembre !== 0 && $store.state.connexionReussi" @click="deconnexion()">
        <p class="titre survole">{{ $t('nav.deconnect') }}</p>
      </a>
    </div>
  </div>
  <!-- Mobile -->
  <div class="navM fond1 cadre is-hidden-desktop">
    <div class="preNav">
      <div class="barre">
        <span v-show="!navMOuvert" class="icon survole is-large" @click="navMOuvert = !navMOuvert">
          <i class="fa-duotone fa-2x fa-bars"></i>
        </span>
        <span v-show="navMOuvert" class="icon survole is-large active" @click="navMOuvert = !navMOuvert">
          <i class="fa-solid fa-3x fa-xmark"></i>
        </span>
      </div>
      <localized-link to="/">
        <div class="barre">
          <p :class="'titre survole ' + ($route.name === 'Accueil' ? 'active' : '')">Dream Web</p>
          <img class="logo" src="@/assets/logo.svg" alt="Logo">
        </div>
      </localized-link>
    </div>
    <div :class="'afterNav ' + (navMOuvert ? 'active' : '')">
      <hr>
      <div class="barre">
        <localized-link v-if="$store.state.numMembre === 0 || !$store.state.connexionReussi" to="/inscription">
          <p :class="'titre survole ' + ($route.name === 'Inscription' ? 'active' : '')">{{ $t('nav.inscription') }}</p>
        </localized-link>
      </div>
      <div class="barre">
        <localized-link v-if="$store.state.numMembre === 0 || !$store.state.connexionReussi" to="/connexion">
          <p :class="'titre survole ' + ($route.name === 'Connexion' ? 'active' : '')">{{ $t('nav.connexion') }}</p>
        </localized-link>
      </div>
      <div class="barre">
        <localized-link v-if="$store.state.numMembre !== 0 && $store.state.connexionReussi" to="/tableaudebord">
          <p :class="'titre survole ' + ($route.name === '' ? 'active' : '')">{{ $t('nav.dashboard') }}</p>
        </localized-link>
      </div>
      <div class="barre">
        <a href="#" v-if="$store.state.numMembre !== 0 && $store.state.connexionReussi" @click="deconnexion()">
          <p class="titre survole">{{ $t('nav.deconnect') }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'BarreNavigation',
  data () {
    return {
      navMOuvert: false
    }
  },
  watch: {
    '$route.name': function (n) {
      this.navMOuvert = false
    }
  },
  methods: {
    deconnexion: function () {
      this.$axios.delete('membre/deconnexion?l=' + this.$i18n.locale + '&t=' + window.localStorage.getItem('tokenDW')).then((data) => {
        this.$store.commit('deconnexion')
        this.$router.push({ name: 'PageAcceuil' })
        window.localStorage.removeItem('tokenDW')
        this.$store.commit('afficherNotification', { texte: this.$t('nav.deconnectOk'), type: 'ok' })
      }).catch((error) => {
        console.log(error)
        this.$store.commit('afficherNotification', { texte: error.response.data.message.join('<br>'), type: 'erreur' })
      }).then(() => {
        this.chargement = false
      })
    }
  }
}
</script>

<style type="text/css" scoped>
@import '@/style/Navigations.css';
.fa-3x {
  font-size: 2.6em;
}
</style>