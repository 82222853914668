<template>
  <footer class="fond1">
    <div class="columns forLargeScreen">
      <SiteMap />
      <div class="column has-text-right-desktop has-text-centered-touch is-offset-2 is-2">
        <localized-link to="/">
          <picture>
            <img class="logo" alt="Logo Dream Web" src="@/assets/logo.svg">
          </picture>
          <p><span class="p survole"> {{ lien }} </span></p>
        </localized-link>
      </div>
    </div>
    <ChangeLangue v-if="charger"/>
  </footer>
</template>

<script type="text/javascript">
import SiteMap from '@/components/App/SiteMap.vue'
import ChangeLangue from '@/components/App/ChangeLangue.vue'

export default {
  name: 'PiedDePage',
  components: {
    ChangeLangue,
    SiteMap
  },
  data () {
    return {
      lien: '\u00A9 2023 Dream Web',
      charger: false
    }
  },
  mounted: function () {
    this.charger = true
  }
}
</script>

<style type="text/css" scoped>
@import '@/style/Footer.css'
</style>
