export default {
  state: {
    notif: null,
    iconListe: {
      ok: 'fa-check',
      erreur: 'fa-times',
      info: 'fa-info',
      attention: 'fa-exclamation'
    },
    modalConfirmationCacher: false
  },
  getters: {},
  mutations: {
    afficherNotification: function (state, {
      texte,
      type
    }) {
      // Générer un identifiant aléatoire
      const idNotif = Math.random().toString().split('.')[1].substr(1, 5)
      const Notif = {
        id: idNotif,
        texte: texte,
        couleur: 'notification ' + type,
        fondProgress: 'progressDiv ' + type,
        icon: '<i class="fas fa-2x ' + state.iconListe[type] + '"></i>',
        afficheBarre: false,
        timer: null
      }
      state.notif = Notif
    },
    cacherNotification: function (state, id) {
      state.notif = null
    },
  },
  actions: {
    cacherModalConfirmation: function ({
      state
    }) {
      state.modalConfirmationCacher = true
      setTimeout(() => {
        state.modalConfirmationCacher = false
      }, 200)
    }
  },
  modules: {}
}
